



























































































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// vue-draggable
import draggable from "vuedraggable";
// Models
import { IApplication } from "@/core/_models/application.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateApplicationDialog from "@/views/admin/_dialogs/applications/CreateApplicationDialog.vue";
import UpdateApplicationDialog from "@/views/admin/_dialogs/applications/UpdateApplicationDialog.vue";

export default Vue.extend({
  name: "Applications",
  components: { draggable, CreateApplicationDialog, ConfirmationDialog, UpdateApplicationDialog },

  data() {
    return {
      isLoading: false,
      isSearching: false,

      search: "",

      // Applications data
      applicationsData: [] as IApplication[]
    };
  },

  beforeMount() {
    this.getAllApplications();
  },

  // Update the indexes of all Applications on Destroy
  beforeDestroy() {
    this.saveLayout();
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    saveLayout(): void {
      this.isLoading = true;

      let index = 0;
      this.applicationsData.forEach((element: IApplication) => {
        element.Index = index;
        index++;

        AdminService.updateApplication(this.currentUser.clientId, element).then(() => {
          this.isLoading = false;
        });
      });
    },

    getAllApplications(): void {
      this.isLoading = true;

      AdminService.getAllApplications(this.currentUser.clientId).then((data: IApplication[]) => {
        if (!data) return;
        this.applicationsData = data;
        this.isLoading = false;
      });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    },

    deleteApplication(elementToDelete: IApplication): void {
      AdminService.deleteApplication(this.currentUser.clientId, elementToDelete.DatabaseId!)
        .then(() => {
          this.getAllApplications();
          this.resetSearch();
          this.ADD_SUCCESS_MESSAGE("Application successfully deleted.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_SUCCESS_MESSAGE("Error deleting application. Please try again.");
        });
    }
  },

  computed: {
    ...mapGetters({
      items: "applications/items",
      currentUser: "currentUser"
    }),

    /**
     * Filters Applications via search bar
     * @author Nick Brahimir
     */
    filteredApplications(): any {
      return this.applicationsData.filter((element: IApplication): any => {
        return element.Name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions(): any {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff(): any {
      return {
        disabled: true
      };
    }
  }
});
